<template>
  <div class="banner">
    <b-row>
      <b-col cols="4">
        <p class="text-header">Service Category</p>
      </b-col>
      <b-col cols="8">
        <div class="d-flex justify-content-end">
          <div class="g-form">
            <div class="input">
              <b-form-input
                v-model="filter.keyword"
                placeholder="Category"
                @focus="iconSearch = true"
                @blur="iconSearch = false"
                @keyup.enter="handleSearch"
              ></b-form-input>
              <b-button
                class="search"
                :class="iconSearch ? 'active-border' : ''"
                @click="handleSearch"
              >
                <b-icon
                  stacked
                  icon="search"
                  scale="0.5"
                  variant="grey"
                ></b-icon>
              </b-button>
            </div>
          </div>

          <b-button class="btn-filter" @click="onClickCreate">
            <span class="d-none d-md-block">
              <span class="">Create</span>
            </span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <Table
      :items="items"
      :fields="fields"
      :isBusy="isBusy"
      :rows="rows"
      :filter="filter"
      :pageOptions="pageOptions"
      @editCategory="onClickEditCategory"
      @filterPage="filterPage"
      @onChangeStatus="onChangeStatus"
    />

    <CategoryForm
      :visible="visible"
      :id="id"
      @hideForm="hideForm"
      @fetchTable="getCategoryList"
    />
  </div>
</template>

<script>
import Table from "./component/table.vue";
import CategoryForm from "./component/categoryForm.vue";
export default {
  name: "CategoryPage",
  components: {
    Table,
    CategoryForm,
  },
  data() {
    return {
      //state
      id: 0,

      // state toggle
      iconSearch: false,
      visible: false,

      // state table
      items: [],

      fields: [
        {
          key: "id",
          label: "Category Id",
          class: "w-10px text-nowrap",
        },
        {
          key: "showImgUrl",
          label: "Image",
          class: "w-10px text-nowrap",
        },
        {
          key: "name",
          label: "Service Category",
          class: "w-100px text-nowrap",
        },
        {
          key: "isActive",
          label: "Status",
          class: "w-50px text-nowrap",
        },
        { key: "actions", label: "Action", class: "w-80px text-nowrap" },
      ],
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 15, text: "15 / page" },
        { value: 20, text: "20 / page" },
      ],
      isBusy: false,
      filter: {
        filter: null,
        keyword: "",
        skip: 1,
        take: 5,
        page: 1,
      },
      rows: 0,

      // state modal
      textModal: "",
      arrStatus: [],
    };
  },

  mounted() {
    this.getCategoryList();
  },
  methods: {
    async getCategoryList() {
      this.isBusy = true;
      const respone = await this.$services.category.getCategoryList(
        this.filter.skip,
        this.filter.take,
        this.filter.keyword
      );
      if (respone.result === 1) {
        const { data, count, skip, take } = respone.detail;
        this.items = data;

        this.rows = count;
      }
      this.isBusy = false;
    },
    handleSearch() {
      this.filter.skip = 1;
      this.getCategoryList();
    },
    onClickEditCategory(val) {
      const { id } = val;
      this.id = id;
      this.visible = true;
    },
    onClickCreate() {
      this.visible = true;
    },
    filterPage(obj) {
      let { page } = obj;
      this.filter.skip = page;

      this.getCategoryList();
    },
    async onChangeStatus(val) {
      const { id, isActive } = val;

      const respone = await this.$services.category.setStatus(id, isActive);
    },
    hideForm() {
      this.id = 0;
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  .g-form {
    width: 100%;
    margin-right: 10px;
  }
  .text-header {
    color: #092d53;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
  }
  .div-divide {
    border-bottom: 1px solid darkgray;
    padding: 10px 0px;
  }

  .header-title {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
  }

  .render-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 200px;
    border: 1px dashed gray;
  }
}
</style>
